import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import WhyUsPage from '../ui/pages/WhyUsPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const About = props => (
  <>
    <HelmetComponent
      title={metaTags.aboutTitle}
      description={metaTags.about}
      page="why-us"
    />
    <AppWrapper {...props}>
      <WhyUsPage />
    </AppWrapper>
  </>
)

export default About
